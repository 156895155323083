<div class="card mb-3">
	<div class="card-header pb-2">
		<!-- New Price Level Input Section -->
		<div class="d-flex justify-content-end mt-3 mb-3">
			<button mat-raised-button color="primary" (click)="toggleNewPriceLevel()">Create New Price Level</button>
		</div>
		<div *ngIf="showNewPriceLevelInput" class="row mt-3">
			<div class="row gx-3 gy-2 align-items-center mb-4">
				<div class="col-4">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>New Price Level Name</mat-label>
						<input matInput [(ngModel)]="newPriceLevelName">
					</mat-form-field>
				</div>
				<div class="col-4">
					<mat-form-field appearance="fill" class="full-width">
						<mat-label>New Price Level ID</mat-label>
						<input matInput [(ngModel)]="newPriceLevelId">
					</mat-form-field>
				</div>
				<div class="col-4">
					<button mat-raised-button color="primary" (click)="createNewPriceLevel()">Save</button>
				</div>
			</div>
		</div>
		<div class="row gx-3 gy-2 align-items-end">
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Price Level</mat-label>
					<mat-select multiple [formControl]="priceLevelForm.get('pricelevels')" appSelectAll>
						<mat-option *ngFor="let level of priceLevels" [value]="level.price_level_id">
							{{ level.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Product Line</mat-label>
					<mat-select multiple [formControl]="priceLevelForm.get('categories')" appSelectAll>
						<mat-option *ngFor="let category of categories" [value]="category.line_field">
							{{ category.line_field }} - {{ category.line_description }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Brand</mat-label>
					<mat-select multiple [formControl]="priceLevelForm.get('brands')" appSelectAll>
						<mat-option *ngFor="let brand of brandOptions" [value]="brand.id">
							{{ brand.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="card-body" id="open-orders">
			<div id="order-parent">
				<!-- FILTER INPUT -->
				<div class="mb-3">
					<mat-form-field>
					  <mat-label>Search product lines</mat-label>
					  <input matInput [formControl]="searchTerm" placeholder="Search product lines..."					  (input)="filterAllProductLines($event.target.value)"
					  >
					</mat-form-field>

				</div>


				<div *ngFor="let brand of brands" class="mb-5">

					<!-- BRAND HEADER -->
					<div class="brand-bar flex-column flex-md-row align-items-start align-items-md-center gap-3">
						<h5 class="brand-header mb-1 mb-md-0">
							{{ brand.brand_name }}
						</h5>

						<div class="brand-input-wrapper">
							<input type="text" class="brand-input" [(ngModel)]="brand.discount_percent" placeholder="Set All Discounts" (keydown.enter)="applyBulkDiscount(brand)" />
							<span class="percent-suffix">%</span>
							<mat-icon class="brand-icon" (click)="applyBulkDiscount(brand)" aria-label="Apply bulk discount">tune</mat-icon>
						</div>
					</div>

					<!-- PRODUCT LINES -->
					<div class="product-line-condensed-wrap" *ngIf="brand.product_lines.length > 0">
						<div class="product-line-condensed-header">
							<small>{{ brand.product_lines.length }} product lines</small>
							<mat-icon class="toggle-icon" (click)="brand.expanded = !brand.expanded" aria-label="Toggle product lines">
								{{ brand.expanded ? 'expand_less' : 'expand_more' }}
							</mat-icon>
						</div>

						<div class="product-line-condensed-body" *ngIf="brand.expanded !== false">
							<div class="product-condensed-row" *ngFor="let result of brand.product_lines; let i = index">

								<div class="condensed-pill-group">
									<span class="pill">{{ result.price_level_id }}</span>
									<span class="pill alt">{{ result.line_field }}</span>
								</div>

								<div class="condensed-desc" [matTooltip]="result.line_description">
									{{ result.line_description }}
								</div>

								<!-- Discount Input -->
								<div class="condensed-input-group">
									<input
									#inputRef
									type="text"
									class="custom-input small"
									[(ngModel)]="result.discountpercent"
									(keydown.enter)="onPriceBlur(result.price_level_id, result.line_field, result.discountpercent, result.manufacturer_id, result)" />

									<span class="percent-suffix">%</span>
								</div>

								<!-- Status -->
								<div class="condensed-status">
									<ng-container *ngIf="isValidDiscount(result.discountpercent); else placeholder">
										<mat-icon inline [ngClass]="{
											'markup-up': isMarkup(result.discountpercent),
											'markup-down': !isMarkup(result.discountpercent)
										}">
											{{ isMarkup(result.discountpercent) ? 'arrow_upward' : 'arrow_downward' }}
										</mat-icon>
										<strong>{{ result.discountpercent }}%</strong>
									</ng-container>
									<ng-template #placeholder>
										<!-- Keeps space reserved -->
										<mat-icon style="visibility: hidden;">arrow_upward</mat-icon>
										<strong style="visibility: hidden;">000.0%</strong>
									</ng-template>
								</div>


								<!-- Save Button -->
								<button
									class="save-btn small"
									type="button"
									(click)="onPriceBlur(result.price_level_id, result.line_field, result.discountpercent, result.manufacturer_id, result)"
									[ngClass]="{ 'clicked': result._clicked }"
									aria-label="Save discount">
									<mat-icon>save</mat-icon>
								</button>

							</div>
						</div>
					</div>

					<!-- Fallback -->
					<div *ngIf="brand.product_lines.length === 0" class="text-muted text-center small">
						No product lines available.
					</div>

				</div>
			</div>
		</div>
	</div>

	<div id="floater-container"></div>